import imageInput from '@/mixins/image-input.js';
import { mapState } from 'vuex';
import PredictionsPostModel from "../../../predictions/models/predictions-post.model";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    name: "NewPrediction",
    components: {
        quillEditor
    },
    data() {
        return {
            item: {},
            content: '',
            // editorOptions: {
       
            //     modules: {
            //         toolbar: [
            //             [{ 'header': [1, 2, false] }],
            //             ['bold', 'italic', 'underline'],
            //             ['image', 'code-block']
            //         ]
            //     }
            // },
            // dtp1: null,
            dtp3: null,
            dtp2: null,

        }
    },
    methods: {
        approve() {
            let data = new PredictionsPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                if (data[key]) {
                    formData.append(key, data[key]);
                }
            }

            this.$store.dispatch("predictions/setItem", formData).then((res) => {
                if (res) {
                    this.item = {};
                    this.date = null;
                    this.resfresh = true;
                }
            });
        },
        changeFirstTeamLogo(e) {
            this.resfresh = false;
            let formData = new FormData();
            formData.append('Photo', e);
            this.$store.dispatch("getFileUrl", formData).then(res => {
                this.item.firstLogo = res;
            })
        },
        changeSecondTeamLogo(e) {
            this.resfresh = false;
            let formData = new FormData();
            formData.append('Photo', e);
            this.$store.dispatch("getFileUrl", formData).then(res => {
                this.item.secondLogo = res;
            })
        },
    },
    computed: {
        ...mapState({
            tournaments: (state) => state.tournaments.items,
            games: (state) => state.games.items,
            categories: state => state.categories.items,
        }),
    },
    mixins: [imageInput],
}
