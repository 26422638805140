export default class MatchModel {
    constructor(item) {
        this._setId(item);
        this._setCompetitors(item);
        this._setCompetitorOdds(item);
        this._setStartDate(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set competitors
     * @param competitors
     * @private
     */
    _setCompetitors({competitors}) {
        let first = competitors[0];
        let second = competitors[1];
        this.firstName = first.name;
        this.firstLogo = first.logo;
        this.firstPredictionScore = first.predictionScore
        this.firstUrl = first.url
        this.firstScore = first.score
        this.secondName = second.name;
        this.secondLogo = second.logo;
        this.secondPredictionScore = second.predictionScore
        this.secondScore = second.score
        this.secondUrl = second.url
    }

    /**
     * set start date
     * @param startDate
     * @private
     */
    _setStartDate({startDate}) {
        let str1 = startDate.split('T');
        this.date = str1[0];
        this.time = str1[1];
    }

    /**
     * set odds
     * @param bets
     * @private
     */
    _setCompetitorOdds({bets}) {
        let odds1 = bets[0].odds;
        odds1.map(i => {
            if (i.name === this.firstName) {
                this.firstOdd = i.value;
            }
            if (i.name === this.secondName) {
                this.secondOdd = i.value;
            }
        });
    }

 
}
